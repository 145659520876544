<template>
    <div class="main-page wizard h-100 pt-3">
        <!-- {{ questions }} -->
        <!-- {{ step }} -->

        <!-- {{ results }} -->
        <!-- {{ words }} -->
        <div class="container" v-if="!resultPage">
            <div class="cross-sign pb-4">
                <button class="btn btn-link" @click="crossGo"><img src="../.././assets/images/cross-icon.svg"></button>
            </div>
            <div class="row">
                <div class="col-md-12 position-relative">
                    <div class="progressbar-outer dir-rtl">
                        <div class="progressbar" :style="'width:' + progressbarWidth + '%'"></div>
                    </div>
                    <!-- <div class="progress-steps">{{ stepTemp }}/{{ totalSteps }}</div> -->
                </div>
            </div>
        </div>
        <div class="container" v-if="!resultPage">
            <div class="row justify-content-center align-items-center">
                <div v-if="loadingDiv" class="loading-spinner">
                    <div class="spinner"></div>
                </div>
                <div class="col-12 mx-auto" v-else>
                    <div class="step-wizard pt-4">

                        <form @submit.prevent.stop="submitForm">

                            <template v-for="(value, i) in questions" :key="i">
                                <!-- {{ value.question_options[0].is_correct }} -->
                                <div v-if="step === value.index">

                                    <div class="row">
                                        <div class="col-12 text-center text-primarydark">

                                            <h5 class="text-primarydark" dir="auto">{{ value.question_title }}</h5>
                                            <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                                v-if="value.question_types.slug != 'choose-name' && value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'fill-in-the-blanks' && value.question_types.slug != 'multiple-blanks'">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />

                                                <span>{{ value.question }}</span>
                                                <span>
                                                    <button class="btn btn-link"
                                                        @click.prevent="playSound(value.audio_file)"
                                                        v-if="value.question_voice_toggle === 1">
                                                        <img src="../.././assets/images/sound-play.svg"
                                                            v-if="!isPlaying">
                                                        <img src="../.././assets/images/sound-play_green.svg"
                                                            v-if="isPlaying">
                                                    </button>
                                                </span>
                                            </h1>
                                            <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                                v-if="value.question_types.slug == 'choose-correct-translation' && value.question_voice_toggle === 1">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />

                                                <span v-if="value.show_text == '1'">{{ value.question }}</span>
                                                <span>
                                                    <button class="btn btn-link"
                                                        @click.prevent="playSound(value.audio_file)">
                                                        <img src="../.././assets/images/sound-play.svg"
                                                            v-if="!isPlaying">
                                                        <img src="../.././assets/images/sound-play_green.svg"
                                                            v-if="isPlaying">

                                                    </button>
                                                </span>
                                            </h1>
                                            <h1 dir="auto" class="text-primarydark fw-bold nanosans pt-3 mb-3"
                                                v-if="value.question_types.slug == 'choose-correct-translation' && value.question_voice_toggle === 0">
                                                <input type="hidden" :value="value.question" id="myHiddenField" />

                                                <span>{{ value.question }}</span>

                                            </h1>

                                            <div v-if="value.question_types.slug == 'choose-name'"
                                                class="mb-3 c-imagechoose"><img :src="value.file" alt="Item Image">
                                            </div>
                                        </div>
                                    </div>
                                    <div dir="auto" class="quiz-options row"
                                        :class="value.question_types.slug == 'voice-translation-with-option-text' ? 'dir-rtl11' : '' || value.question_types.slug == 'write-name' ? 'height-custom' : ''"
                                        v-if="value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'tap-the-pairs' && value.question_types.slug != 'fill-in-the-blanks' && value.question_types.slug != 'multiple-blanks'">
                                        <div class="col-6 col-md-6 mx-auto" v-for="(items, i) in value.question_options"
                                            :key="i" :class="changeColor">
                                            <div dir="auto" class="box-shaper"
                                                v-if="value.question_types.slug != 'write-name' && value.question_types.slug != 'voice-translation-with-option-image'"
                                                :class="'iscorrect-' + items.is_correct">

                                                <input type="radio" :id="items.option + '_' + items.question_id"
                                                    name="answers"
                                                    @change='onToggle(items.question_id, items.is_correct, items.option, i, items.option_voice_toggle, items.audio_file)'
                                                    :value="items.option + '_' + items.question_id"
                                                    v-model="selectoptions" :disabled="this.changeColor != ''" />

                                                <label dir="auto" :class="this.changeColor != '' ? 'nothover' : ''"
                                                    :for="items.option + '_' + items.question_id">
                                                    {{ items.option }}
                                                </label>
                                            </div>

                                            <div class="box-shaperimg" v-if="value.question_types.slug == 'write-name'">
                                                <div class="box">
                                                    <img :src="items.option_file">
                                                </div>
                                                <p dir="auto">{{ items.option }}</p>


                                                <div dir="auto">
                                                    <input type="text" v-model="writename" placeholder="Write..."
                                                        @change='handleWritName(items.question_id, items.correct_answer)'
                                                        :disabled="this.changeColor != ''" @keydown.enter.prevent=""
                                                        @paste.prevent>
                                                </div>
                                                <div class="mt-4" v-if="changeColor === 'check-red'">
                                                    <span class="btn btn-green">{{ items.correct_answer }}</span>
                                                </div>
                                            </div>
                                            <div dir="auto" class="box-shaper"
                                                v-if="value.question_types.slug == 'voice-translation-with-option-image'"
                                                :class="'iscorrect-' + items.is_correct">
                                                <input type="radio" :id="'imageoptic_' + items.id" name="answers"
                                                    @change='onToggled(items.question_id, items.is_correct, items.option_voice_toggle)'
                                                    :value="items.option + items.id" v-model="selectoptions"
                                                    :disabled="this.changeColor != ''" />
                                                <label dir="auto" :class="this.changeColor != '' ? 'nothover' : ''"
                                                    :for="'imageoptic_' + items.id">
                                                    <img :src="items.option_file" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="value.question_types.slug == 'choose-correct-translation'">
                                        <CheckStence :quizwords="value.question_options" :wordvalue="value"
                                            @correct-check="handleAllChecked" @notdis="checkbtndis"
                                            :isAnsChecked="this.isAnsChecked" @checBtn="checBtnis" />

                                    </div>

                                    <div v-if="value.question_types.slug == 'fill-in-the-blanks'">
                                        <FillBlank :quizwords="value.question_options" :wordvalue="value"
                                            @correct-check="handleAllChecked" @notdis="checkbtndis"
                                            :isAnsChecked="this.isAnsChecked" @checBtn="checBtnis"
                                            :pauseAudio="this.fillblankComponent" />

                                    </div>

                                    <div v-if="value.question_types.slug == 'multiple-blanks'">
                                        <MultiFillBlank :quizwords="value.question_options" :wordvalue="value"
                                            @correct-check="handleAllChecked" @notdis="checkbtndis"
                                            :isAnsChecked="this.isAnsChecked" @checBtn="checBtnis" />

                                    </div>


                                    <div class="quiz-options row height-custom"
                                        v-if="value.question_types.slug == 'tap-the-pairs'">

                                        <WordsMatch :quizoptions="value.questionOptions"
                                            :quizoptionsecond="value.questionOptionsSecond"
                                            @all-matched="handleAllMatched" @checBtn="checBtnis" :questionid="value.id"
                                            :allmatched="this.isAllMatched" />
                                    </div>

                                    <div class="row mt-5"
                                        v-if="isAnsChecked && value.question_types.slug != 'tap-the-pairs' && value.question_types.slug != 'choose-correct-translation' && value.question_types.slug != 'fill-in-the-blanks' && value.question_types.slug != 'multiple-blanks'">
                                        <div class="col-md-12 col-12 text-center pb-3">

                                            <button type="button"
                                                @click="checkAnswers(value.id, value.question_options[i].is_correct)"
                                                class="btn btn-main btn-lg py-3 w-100" :disabled="!notdis" tabindex="0">
                                                تحقق
                                            </button>
                                        </div>
                                    </div>

                                    <!-- correct and incorrect popups -->
                                    <div class="correct-popup">
                                        <div class="modal fade show"
                                            :class="{ 'show': modal, 'success': changemColor, 'badsuccess': !changemColor }"
                                            id="exampleModal" tabindex="-1" role="dialog"
                                            aria-labelledby="exampleModalLabel" aria-hidden="true"
                                            :style="modal === true ? 'display: flex; padding-right: 17px;' : ''">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="catfaces">
                                                        <div class="success-cat" v-if="changemColor">
                                                            <svg width="131" height="131" viewBox="0 0 131 131"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg"
                                                                v-sad-cat>
                                                                <g clip-path="url(#clip0_250_32)">
                                                                    <rect width="131" height="131" rx="65.5"
                                                                        fill="#F3F3F8" />
                                                                    <path
                                                                        d="M92.3622 57.2533L91.1989 42.8565C91.0888 42.5491 90.9029 42.2741 90.6583 42.0567C90.4137 41.8392 90.1181 41.6862 89.7986 41.6117C89.4791 41.5372 89.1459 41.5436 88.8295 41.6302C88.5131 41.7168 88.2237 41.881 87.9876 42.1076C78.6518 44.3848 74.8847 49.2308 73.8056 55.3169C69.3255 54.3728 64.7198 54.1667 60.1725 54.7069C63.4543 50.0627 63.2281 45.3293 59.8142 40.515C59.7182 40.2 59.5436 39.9143 59.3067 39.6845C59.0698 39.4546 58.7782 39.2881 58.4591 39.2004C58.14 39.1127 57.8038 39.1067 57.4818 39.183C57.1597 39.2592 56.8623 39.4152 56.6172 39.6365C50.3026 42.8096 45.8858 46.7638 45.1746 52.2436C37.244 56.472 30.8847 63.1152 27.0289 71.1992C23.1731 79.2832 22.0236 88.3827 23.7491 97.1635C25.4746 105.944 29.9842 113.945 36.6167 119.991C43.2493 126.038 51.6559 129.813 60.604 130.763C69.5521 131.713 78.5709 129.788 86.3382 125.27C94.1055 120.752 100.213 113.879 103.764 105.658C107.316 97.4377 108.125 88.3021 106.073 79.5909C104.021 70.8797 99.2158 63.0513 92.3622 57.2533Z"
                                                                        fill="#3B3786" />
                                                                    <path
                                                                        d="M62.6304 127.596C57.6654 127.64 52.7447 126.691 48.1774 124.81C38.6415 120.811 34.2708 114.063 34.0894 113.777C34.0297 113.682 34.0115 113.568 34.0387 113.459C34.066 113.351 34.1364 113.257 34.2348 113.199C34.3331 113.14 34.4514 113.122 34.5638 113.147C34.6762 113.173 34.7737 113.24 34.8349 113.335C38.2896 118.074 43.047 121.787 48.57 124.053C57.1572 127.639 71.8213 129.436 92.9142 117.629C92.9639 117.602 93.0188 117.583 93.0758 117.576C93.1328 117.568 93.1908 117.572 93.2464 117.586C93.302 117.601 93.3542 117.625 93.3999 117.659C93.4456 117.693 93.484 117.735 93.5128 117.783C93.5417 117.831 93.5604 117.884 93.568 117.939C93.5756 117.994 93.5719 118.05 93.557 118.104C93.5422 118.158 93.5165 118.208 93.4815 118.252C93.4465 118.296 93.4029 118.333 93.353 118.361C80.9712 125.292 70.7482 127.596 62.6304 127.596Z"
                                                                        fill="white" />

                                                                    <path
                                                                        d="M63.7991 101.227C62.897 101.226 62.0321 100.912 61.3942 100.354C60.7563 99.7952 60.3975 99.038 60.3965 98.2481C60.3971 98.1356 60.4487 98.0279 60.5398 97.9486C60.6308 97.8693 60.7541 97.8247 60.8826 97.8247C61.0111 97.8247 61.1343 97.8693 61.2254 97.9486C61.3165 98.0279 61.368 98.1356 61.3686 98.2481C61.3697 98.8119 61.6262 99.3523 62.0819 99.7506C62.5376 100.149 63.1552 100.373 63.7991 100.373C64.443 100.373 65.0606 100.149 65.5163 99.7506C65.972 99.3523 66.2285 98.8119 66.2295 98.2481C66.2302 98.1356 66.2817 98.0279 66.3728 97.9486C66.4639 97.8693 66.5871 97.8247 66.7156 97.8247C66.8441 97.8247 66.9674 97.8693 67.0584 97.9486C67.1495 98.0279 67.201 98.1356 67.2017 98.2481C67.2007 99.038 66.8419 99.7952 66.204 100.354C65.5661 100.912 64.7012 101.226 63.7991 101.227Z"
                                                                        fill="white" class="tail2" id="cat-mouth" />


                                                                    <path
                                                                        d="M64.6497 93.5716C66.9987 93.5716 68.903 92.6195 68.903 91.445C68.903 90.2705 66.9987 89.3184 64.6497 89.3184C62.3007 89.3184 60.3965 90.2705 60.3965 91.445C60.3965 92.6195 62.3007 93.5716 64.6497 93.5716Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M37.388 91.8699C38.5373 90.4727 39.9747 89.3488 41.5986 88.5776C43.2226 87.8064 44.9934 87.4067 46.7862 87.4067C48.5789 87.4067 50.3498 87.8064 51.9737 88.5776C53.5976 89.3488 55.035 90.4727 56.1843 91.8699C58.1314 89.9824 59.4836 87.5509 60.0698 84.883C60.6561 82.2151 60.4501 79.4305 59.478 76.8814C58.5058 74.3324 56.8111 72.1333 54.6082 70.5623C52.4053 68.9913 49.7931 68.1189 47.102 68.0555C44.4108 67.9921 41.7616 68.7404 39.4893 70.206C37.2169 71.6715 35.4236 73.7884 34.336 76.2889C33.2485 78.7894 32.9155 81.5613 33.3793 84.254C33.8431 86.9466 35.0827 89.4392 36.9415 91.4164C37.0868 91.5711 37.2357 91.7223 37.388 91.8699Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M74.8158 91.8699C75.965 90.4727 77.4024 89.3488 79.0264 88.5776C80.6503 87.8064 82.4211 87.4067 84.2139 87.4067C86.0066 87.4067 87.7775 87.8064 89.4014 88.5776C91.0254 89.3488 92.4627 90.4727 93.612 91.8699C95.5591 89.9824 96.9113 87.5509 97.4976 84.883C98.0839 82.2151 97.8779 79.4305 96.9057 76.8815C95.9336 74.3324 94.2389 72.1333 92.036 70.5623C89.8331 68.9913 87.2209 68.1189 84.5297 68.0555C81.8386 67.9921 79.1893 68.7404 76.917 70.206C74.6447 71.6715 72.8514 73.7884 71.7638 76.2889C70.6762 78.7894 70.3433 81.5612 70.807 84.2539C71.2708 86.9466 72.5104 89.4392 74.3692 91.4164C74.5145 91.5711 74.6634 91.7223 74.8158 91.8699Z"
                                                                        fill="white" />

                                                                    <path
                                                                        d="M46.6786 91.2078C49.2625 91.2078 51.3571 88.9227 51.3571 86.1039C51.3571 83.2851 49.2625 81 46.6786 81C44.0947 81 42 83.2851 42 86.1039C42 88.9227 44.0947 91.2078 46.6786 91.2078Z"
                                                                        fill="#3B3786" id="cat-eye" />
                                                                    <path
                                                                        d="M84.6786 91.2078C87.2625 91.2078 89.3571 88.9227 89.3571 86.1039C89.3571 83.2851 87.2625 81 84.6786 81C82.0947 81 80 83.2851 80 86.1039C80 88.9227 82.0947 91.2078 84.6786 91.2078Z"
                                                                        fill="#3B3786" id="cat-eye2" />

                                                                    <path
                                                                        d="M84.6394 101.227C86.7535 101.227 88.4674 99.5133 88.4674 97.3992C88.4674 95.2851 86.7535 93.5713 84.6394 93.5713C82.5253 93.5713 80.8115 95.2851 80.8115 97.3992C80.8115 99.5133 82.5253 101.227 84.6394 101.227Z"
                                                                        fill="#EEB940" />
                                                                    <path
                                                                        d="M46.7855 101.227C49.1345 101.227 51.0387 99.5133 51.0387 97.3992C51.0387 95.2851 49.1345 93.5713 46.7855 93.5713C44.4365 93.5713 42.5322 95.2851 42.5322 97.3992C42.5322 99.5133 44.4365 101.227 46.7855 101.227Z"
                                                                        fill="#EEB940" />
                                                                </g>
                                                                <rect x="6" y="6" width="119" height="119" rx="59.5"
                                                                    stroke="#BAF2BC" stroke-width="12" />
                                                                <defs>
                                                                    <clipPath id="clip0_250_32">
                                                                        <rect width="131" height="131" rx="65.5"
                                                                            fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                        <div class="success-cat" v-else>
                                                            <svg width="131" height="131" viewBox="0 0 131 131"
                                                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_263_91)">
                                                                    <rect width="131" height="131" rx="65.5"
                                                                        fill="#F3F3F8" />
                                                                    <path
                                                                        d="M92.3622 57.2533L91.1989 42.8565C91.0888 42.5491 90.9029 42.2741 90.6583 42.0567C90.4137 41.8392 90.1181 41.6862 89.7986 41.6117C89.4791 41.5372 89.1459 41.5436 88.8295 41.6302C88.5131 41.7168 88.2237 41.881 87.9876 42.1076C78.6518 44.3848 74.8847 49.2308 73.8056 55.3169C69.3255 54.3728 64.7198 54.1667 60.1725 54.7069C63.4543 50.0627 63.2281 45.3293 59.8142 40.515C59.7182 40.2 59.5436 39.9143 59.3067 39.6845C59.0698 39.4546 58.7782 39.2881 58.4591 39.2004C58.14 39.1127 57.8038 39.1067 57.4818 39.183C57.1597 39.2592 56.8623 39.4152 56.6172 39.6365C50.3026 42.8096 45.8858 46.7638 45.1746 52.2436C37.244 56.472 30.8847 63.1152 27.0289 71.1992C23.1731 79.2832 22.0236 88.3827 23.7491 97.1635C25.4746 105.944 29.9842 113.945 36.6167 119.991C43.2493 126.038 51.6559 129.813 60.604 130.763C69.5521 131.713 78.5709 129.788 86.3382 125.27C94.1055 120.752 100.213 113.879 103.764 105.658C107.316 97.4377 108.125 88.3021 106.073 79.5909C104.021 70.8797 99.2158 63.0513 92.3622 57.2533Z"
                                                                        fill="#3B3786" />
                                                                    <path
                                                                        d="M62.6304 127.596C57.6654 127.64 52.7447 126.691 48.1774 124.81C38.6415 120.811 34.2708 114.063 34.0894 113.777C34.0297 113.682 34.0115 113.568 34.0387 113.459C34.066 113.351 34.1364 113.257 34.2348 113.199C34.3331 113.14 34.4514 113.122 34.5638 113.147C34.6762 113.173 34.7737 113.24 34.8349 113.335C38.2896 118.074 43.047 121.787 48.57 124.053C57.1572 127.639 71.8213 129.436 92.9142 117.629C92.9639 117.602 93.0188 117.583 93.0758 117.576C93.1328 117.568 93.1908 117.572 93.2464 117.586C93.302 117.601 93.3542 117.625 93.3999 117.659C93.4456 117.693 93.484 117.735 93.5128 117.783C93.5417 117.831 93.5604 117.884 93.568 117.939C93.5756 117.994 93.5719 118.05 93.557 118.104C93.5422 118.158 93.5165 118.208 93.4815 118.252C93.4465 118.296 93.4029 118.333 93.353 118.361C80.9712 125.292 70.7482 127.596 62.6304 127.596Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M63.7986 97.8245C64.7007 97.8253 65.5655 98.1395 66.2034 98.698C66.8413 99.2565 67.2002 100.014 67.2012 100.804C67.2005 100.916 67.149 101.024 67.0579 101.103C66.9668 101.183 66.8436 101.227 66.7151 101.227C66.5866 101.227 66.4633 101.183 66.3722 101.103C66.2812 101.024 66.2297 100.916 66.229 100.804C66.228 100.24 65.9714 99.6995 65.5158 99.3012C65.0601 98.9028 64.4425 98.6791 63.7986 98.6791C63.1546 98.6791 62.5371 98.9028 62.0814 99.3012C61.6257 99.6995 61.3692 100.24 61.3681 100.804C61.3675 100.916 61.316 101.024 61.2249 101.103C61.1338 101.183 61.0105 101.227 60.8821 101.227C60.7536 101.227 60.6303 101.183 60.5392 101.103C60.4481 101.024 60.3966 100.916 60.396 100.804C60.397 100.014 60.7558 99.2565 61.3937 98.698C62.0316 98.1395 62.8964 97.8253 63.7986 97.8245Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M64.6497 93.5716C66.9987 93.5716 68.903 92.6195 68.903 91.445C68.903 90.2705 66.9987 89.3184 64.6497 89.3184C62.3007 89.3184 60.3965 90.2705 60.3965 91.445C60.3965 92.6195 62.3007 93.5716 64.6497 93.5716Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M37.388 91.8699C38.5373 90.4727 39.9747 89.3488 41.5986 88.5776C43.2226 87.8064 44.9934 87.4067 46.7862 87.4067C48.5789 87.4067 50.3498 87.8064 51.9737 88.5776C53.5976 89.3488 55.035 90.4727 56.1843 91.8699C58.1314 89.9824 59.4836 87.5509 60.0698 84.883C60.6561 82.2151 60.4501 79.4305 59.478 76.8814C58.5058 74.3324 56.8111 72.1333 54.6082 70.5623C52.4053 68.9913 49.7931 68.1189 47.102 68.0555C44.4108 67.9921 41.7616 68.7404 39.4893 70.206C37.2169 71.6715 35.4236 73.7884 34.336 76.2889C33.2485 78.7894 32.9155 81.5613 33.3793 84.254C33.8431 86.9466 35.0827 89.4392 36.9415 91.4164C37.0868 91.5711 37.2357 91.7223 37.388 91.8699Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M74.8158 91.8699C75.965 90.4727 77.4024 89.3488 79.0264 88.5776C80.6503 87.8064 82.4211 87.4067 84.2139 87.4067C86.0066 87.4067 87.7775 87.8064 89.4014 88.5776C91.0254 89.3488 92.4627 90.4727 93.612 91.8699C95.5591 89.9824 96.9113 87.5509 97.4976 84.883C98.0839 82.2151 97.8779 79.4305 96.9057 76.8815C95.9336 74.3324 94.2389 72.1333 92.036 70.5623C89.8331 68.9913 87.2209 68.1189 84.5297 68.0555C81.8386 67.9921 79.1893 68.7404 76.917 70.206C74.6447 71.6715 72.8514 73.7884 71.7638 76.2889C70.6762 78.7894 70.3433 81.5612 70.807 84.2539C71.2708 86.9466 72.5104 89.4392 74.3692 91.4164C74.5145 91.5711 74.6634 91.7223 74.8158 91.8699Z"
                                                                        fill="white" />
                                                                    <path
                                                                        d="M46.6786 92.2078C49.2625 92.2078 51.3571 89.9227 51.3571 87.1039C51.3571 84.2851 49.2625 82 46.6786 82C44.0947 82 42 84.2851 42 87.1039C42 89.9227 44.0947 92.2078 46.6786 92.2078Z"
                                                                        fill="#3B3786" />
                                                                    <path
                                                                        d="M84.6786 92.2078C87.2625 92.2078 89.3571 89.9227 89.3571 87.1039C89.3571 84.2851 87.2625 82 84.6786 82C82.0947 82 80 84.2851 80 87.1039C80 89.9227 82.0947 92.2078 84.6786 92.2078Z"
                                                                        fill="#3B3786" />
                                                                    <path
                                                                        d="M84.6394 101.227C86.7535 101.227 88.4674 99.5133 88.4674 97.3992C88.4674 95.2851 86.7535 93.5713 84.6394 93.5713C82.5253 93.5713 80.8115 95.2851 80.8115 97.3992C80.8115 99.5133 82.5253 101.227 84.6394 101.227Z"
                                                                        fill="#EEB940" />
                                                                    <path
                                                                        d="M46.7855 101.227C49.1345 101.227 51.0387 99.5133 51.0387 97.3992C51.0387 95.2851 49.1345 93.5713 46.7855 93.5713C44.4365 93.5713 42.5322 95.2851 42.5322 97.3992C42.5322 99.5133 44.4365 101.227 46.7855 101.227Z"
                                                                        fill="#EEB940" />
                                                                </g>
                                                                <rect x="6" y="6" width="119" height="119" rx="59.5"
                                                                    stroke="#F2BABA" stroke-width="12" />
                                                                <defs>
                                                                    <clipPath id="clip0_263_91">
                                                                        <rect width="131" height="131" rx="65.5"
                                                                            fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div class="modal-body text-center pt-5 pb-5">
                                                        <h3 class="nanosans fw-bold" v-if="changemColor">إجابتك صحيحة!
                                                        </h3>
                                                        <h3 class="nanosans fw-bold" v-else>إجابتك غير صحيحة!</h3>
                                                        <div class="correct-ans" v-if="changemColor">

                                                            <div class="write-name"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <div class="write-answ">
                                                                        <div class="inside-img">
                                                                            <img :src="items.option_file"
                                                                                alt="Item Image">
                                                                        </div>
                                                                        <span>{{ items.option }}</span>
                                                                    </div>
                                                                    <div class="writed-answ">

                                                                        <span :class="'leseng-word'">{{
                                                                            items.correct_answer }}</span>

                                                                        <button class="btn btn-link speakwordds"
                                                                            @click.prevent="speakwordds(items.audio_file)"
                                                                            v-if="items.option_voice_toggle === 1">
                                                                            <img
                                                                                src="../../assets/images/sound-wplay.svg">
                                                                        </button>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div v-if="value.question_types.slug == 'choose-name'"
                                                                class="mb-0 c-imagechoose img-ans">
                                                                <img :src="value.file" alt="Item Image">
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word">{{ items.option }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-image'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word"> {{ value.question }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(value.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <span class="leseng-word"
                                                                        v-if="items.is_correct === 1">{{ items.option
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div v-if="value.question_types.slug != 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <img :src="items.option_file" alt=""
                                                                        v-if="items.is_correct === 1" height="54">
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="text-center" v-else>

                                                            <div class="write-name"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <div class="write-answ">
                                                                        <div class="inside-img">
                                                                            <img :src="items.option_file"
                                                                                alt="Item Image">
                                                                        </div>
                                                                        <span>{{ items.option }}</span>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div v-if="value.question_types.slug == 'choose-name'">
                                                                <img :src="value.file" alt="Item Image">
                                                            </div>
                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name' && value.question != null || value.question_types.slug ===
                                                                    'voice-translation-with-option-image' || value.question_types.slug === 'voice-translation-with-option-text'">
                                                                {{ value.question }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="modal-footer text-center d-inline-block">
                                                        <div v-if="!changemColor">
                                                            <h5 class="nanosans">اجابة صحيحة :</h5>


                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'write-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <span class="leseng-word">{{ items.correct_answer
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i"><span v-if="items.is_correct === 1">{{
            items.option }}</span></div>
                                                            </div>

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i"><span v-if="items.is_correct === 1">{{
            items.option }}</span></div>
                                                            </div> -->

                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-text'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">

                                                                    <!-- <code>{{ items }}</code> -->
                                                                    <span v-if="items.is_correct === 1"
                                                                        class="leseng-word">{{ items.option }}
                                                                    </span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div class="text-ansc"
                                                                v-if="value.question_types.slug === 'choose-name'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <span class="leseng-word"
                                                                        v-if="items.is_correct === 1">{{ items.option
                                                                        }}</span>
                                                                    <button
                                                                        class="btn btn-link speakwordds w-auto pb-0 pe-0"
                                                                        @click.prevent="speakwordds(items.audio_file)"
                                                                        v-if="items.option_voice_toggle === 1 && items.is_correct === 1">
                                                                        <img src="../../assets/images/sound-wplay.svg">
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div
                                                                v-if="value.question_types.slug === 'voice-translation-with-option-image'">
                                                                <div v-for="(items, i) in value.question_options"
                                                                    :key="i">
                                                                    <div class="text-ansc"
                                                                        v-if="items.is_correct === 1">
                                                                        <img :src="items.option_file" alt=""
                                                                            height="54">
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="row"
                                                            v-if="!isAnsChecked || value.question_types.slug == 'tap-the-pairs'">

                                                            <div class="col-md-12 col-12 text-center pb-3">
                                                                <button type="button" @click="wizardNext(value.id)"
                                                                    class="btn btn-main btn-lg py-3 w-100"
                                                                    v-if="!isLastQuestion(i) && questions.length > 0"
                                                                    :disabled="!notdis && !isAllMatched">
                                                                    التالي
                                                                </button>

                                                                <button type="submit" @click="submitForm"
                                                                    class="btn btn-main btn-lg py-3 w-100" v-else
                                                                    :disabled="!notdis && !isAllMatched">
                                                                    <div class="spinner" v-if="loadingDiv"></div>
                                                                    تابع
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <!-- <button type="button" class="btn btn-main btn-lg py-3 w-100"
                                                            @click="wizardNext(value.id)">التالي</button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- correct and incorrect popups -->

                                    <div class="row mt-5"
                                        v-if="!isAnsChecked || value.question_types.slug == 'tap-the-pairs'">

                                        <div class="col-md-12 col-12 text-center pb-3">
                                            <button type="button" @click="wizardNext(value.id)"
                                                class="btn btn-main btn-lg py-3 w-100"
                                                v-if="!isLastQuestion(i) && questions.length > 0"
                                                :disabled="!notdis && !isAllMatched">
                                                التالي
                                            </button>

                                            <button type="submit" @click="submitForm"
                                                class="btn btn-main btn-lg py-3 w-100" v-else
                                                :disabled="!notdis && !isAllMatched">
                                                <div class="spinner" v-if="loadingDiv"></div>
                                                تابع
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </template>


                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center wizard-last container h-100" v-if="resultPage">
            <div class="container h-100">
                <div class="row align-content-center d-flex h-100">
                    <div class="col-12" dir="auto">
                        <img src="../.././assets/images/apple.svg" width="90">
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '1'">
                            لقد أكملت الدرس الأول من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '2'">
                            لقد أكملت الدرس الثاني من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '3'">
                            لقد أكملت الدرس الثالث من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '4'">
                            لقد أكملت الدرس الرابع من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '5'">
                            لقد أكملت الدرس الخامس من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '6'">
                            لقد أكملت الدرس السادس من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '7'">
                            لقد أكملت الدرس السابع من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '8'">
                            لقد أكملت الدرس الثامن من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '9'">
                            لقد أكملت الدرس التاسع من درس {{ catName }}!
                        </h2>
                        <h2 class="text-primarydark fw-bold nanosans pt-3 mb-3" v-if="lessonnumer === '10'">
                            لقد أكملت الدرس العاشر من درس {{ catName }}!
                        </h2>
                        <h1 class="text-secondary fw-bold pt-3 mb-3">{{ toArabicNumber(correctCounts) }} / {{
                            toArabicNumber(totalSteps) }}
                        </h1>
                        <h3 class="text-primarydark fw-normal nanosans pt-3 mb-3">جيد!</h3>
                    </div>
                    <div class="col-lg-7 mx-auto">
                        <img src="../../assets/images/cat-language-app-loading.gif" class="w-44">
                        <div class="d-md-grid gap-2 d-md-flex justify-content-center">
                            <router-link :to="savedPath" class="btn btn-main btn-lg py-3 w-80">
                                الدرس التالي
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- {{ results }} -->

    </div>

    <!-- <WordDay /> -->
</template>

<script>
import WordsMatch from "@/components/WordsMatch";
import CheckStence from "@/components/CheckStence";
import FillBlank from "@/components/FillBlank";
import MultiFillBlank from "@/components/MultiFillBlank";
import '../../assets/resources/js/notify';

// const VueTelInput = window['vue-tel-input'].VueTelInput;
// Vue.component('vue-tel-input', VueTelInput.VueTelInput)
import axios from 'axios';

// import WordDay from '../../components/common/WordDayPopup.vue';
// import quizes from "../.././assets/quiz-landing";
// import contents from "../.././assets/content-templates.js";
import trumpetSfx1 from '../../assets/audio/hoesound.mp3';
import correctSound1 from '../../assets/audio/correct.wav';
import wrongSound1 from '../../assets/audio/wrong.wav';
import finishLevel1 from '../../assets/audio/finishlevel.wav';

import sadCatDirective from '../../directives/s-happy-cat';

const currentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export default {
    directives: {
        'sad-cat': sadCatDirective,
    },
    components: {
        WordsMatch,
        CheckStence,
        FillBlank,
        MultiFillBlank
        // WordDay
    },
    data() {
        return {
            lessonnumer: `${localStorage.getItem('lessonNumber')}`,
            catName: `${localStorage.getItem('categoryname')}`,
            resultPage: false,
            loadingDiv: true,
            timestamp: '',
            progressbarWidth: 15,
            totalSteps: '',
            step: 1,
            changemColor: false,
            quizSelected: [],
            showField: false,
            showTemplate: false,
            questions: '',
            modal: false,
            writename: '',
            corectImage: '',
            sentenceMatch: [],
            selectedArabicWord: [], // Arabic selected option
            selectedEnglishWord: [], // English selected option
            changeColor: '',
            correctCounts: '',
            selectoptions: '',
            // userResponses: [],
            results: [],
            userId: 0,
            headText: '',
            isArabic: [],
            notdis: false,
            isAllMatched: false,
            stepIndex: 0,
            wrongAnswers: [],
            isAnsChecked: true,
            custIndex: 0,
            hasMistak: null,
            savedPath: `${localStorage.getItem('currentPath')}`,
            currentAudio: null,
            correctSound: null,
            wrongSound: null,
            finishLevel: null,
            trumpetSfx: null,
            fillblankComponent: false,
            isPlaying: false,
            // wordsArabic: null,
            // wordsEnglish: null,
        }
    },
    watch: {
        writename(valNew) {
            if (valNew.length > 0) {
                this.notdis = true;
            } else {
                this.notdis = false;
            }
        }
    },
    created() {
        setInterval(this.getNow, 1000);
        // if (this.questions && this.questions.questionOptions) {
        //     this.dataWordMatch = this.questions.questionOptions.map(item => {
        //         return {
        //             key: item.key,
        //             value: item.value.toLowerCase(), // Convert value to lowercase
        //         };
        //     });
        // } else {
        //     // Handle the case where this.questions or this.questions.questionOptions is undefined
        //     console.error("Questions or questionOptions is undefined.");
        // }

    },

    mounted() {
        this.fetchData();
        const dataget = JSON.parse(localStorage.getItem('userData'));
        if (dataget) {
            this.userId = dataget.id
        }
        this.correctSound = new Audio(correctSound1);
        this.wrongSound = new Audio(wrongSound1);
        this.finishLevel = new Audio(finishLevel1);
        this.trumpetSfx = new Audio(trumpetSfx1);

        this.correctSound.load();
        this.wrongSound.load();
        this.finishLevel.load();
        this.trumpetSfx.load();

    },

    methods: {
        // handleEnterKey(){
        //     this.checkAnswers();
        // },
        toArabicNumber(num) {
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return num
                .toString()
                .split('')
                .map(digit => arabicDigits[digit])
                .join('');
        },

        speakwordds(name) {

            if (name) {

                if (this.currentAudio && !this.currentAudio.paused) {
                    this.currentAudio.pause();
                    this.currentAudio.currentTime = 0;
                }

                this.currentAudio = new Audio(name)
                this.currentAudio.play();
            }

        },

        checkbtndis(buttonSet) {
            this.notdis = buttonSet;
            // console.log(this.notdis);

        },
        checBtnis(value) {
            this.isAnsChecked = value;
        },
        checBtniss(value) {
            this.isAnsChecked = value;
        },
        handleAllMatched(isAllMatched, correct, isCoreect) {
            this.isAllMatched = isAllMatched;
            // console.log('qID', correct)
            if (correct == 1) {
                this.results.push({ question_id: isCoreect, is_correct: correct, correct: isAllMatched });
                // this.wrongAnswers.push({ question_id: QuesId, is_correct: isCoreect, correct: true });

            } else {
                this.results.push({ question_id: isCoreect, is_correct: 0, correct: false });
                // this.wrongAnswers.push({ question_id: QuesId, is_correct: 0, correct: false });
            }

        },
        handleAllChecked(isCoreecty, QuesIdd) {
            if (isCoreecty == 1) {
                // this.wrongAnswers.push({ question_id: QuesIdd, is_correct: isCoreecty, correct: true });
                const existingItem = this.results.find(item => item.question_id === QuesIdd);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCoreecty;
                    existingItem.correct = isCoreecty === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: QuesIdd, is_correct: isCoreecty, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: QuesIdd, is_correct: 0, correct: false });
                const existingItem = this.results.find(item => item.question_id === QuesIdd);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCoreecty;
                    existingItem.correct = isCoreecty === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: QuesIdd, is_correct: 0, correct: false });
                }
            }
        },


        checkLang(vl) {
            const arabicPattern = /[\u062a\u0641\u0627\u062d\u0629]/;
            if (vl !== null) {
                let ar = arabicPattern.test(vl)
                this.isArabic.push((ar == true) ? 'isArabic' : 'isEnglish');
            }
        },
        // selectWord(questionId, englishWord) {
        //     console.log("Selected question_id:", questionId);
        //     console.log("Selected English Word:", englishWord, this.selectedArabicWord);

        //     if (englishWord == this.selectedArabicWord || this.selectedArabicWord == englishWord) {
        //         console.log(this.changeColor);
        //         this.results.push({ question_id: questionId, is_correct: 1, correct: true });
        //     } else {
        //         // this.changeColor = 'check-red';
        //         console.log('anwser not right', this.changeColor);
        //         this.results.push({ question_id: questionId, is_correct: 0, correct: false });
        //     }
        // },

        onToggle(questionId, isCorrect, itemValue, i, voiceopt, voicsound) {
            // console.log('yes it is', );
            console.log(i, voiceopt);
            this.notdis = true;
            // let results = []
            if (isCorrect == 1) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                }
            }

            if (this.currentAudio) {
                this.currentAudio.pause();
                this.currentAudio.currentTime = 0;
            }
            if (voicsound) {
                this.currentAudio = new Audio(voicsound);
                this.currentAudio.play();

            }

        },
        onToggled(questionId, isCorrect, voiceopt) {
            console.log('yes it is', voiceopt);
            this.notdis = true;
            // let results = []
            if (isCorrect == 1) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 1;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = isCorrect;
                    existingItem.correct = isCorrect === 0;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: isCorrect, correct: false });
                }
            }
        },

        handleWritName(questionId, correctAnswer) {
            const userInput = this.writename;
            const userInputLower = userInput.toLowerCase();
            const correctAnswerLower = correctAnswer.toLowerCase();

            // console.log('check before', userInputLower)
            if (correctAnswerLower === userInputLower) {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: 1, correct: true });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = 1;
                    existingItem.correct = true;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: 1, correct: true });
                }

            } else {
                // this.wrongAnswers.push({ question_id: questionId, is_correct: 0, correct: false });
                const existingItem = this.results.find(item => item.question_id === questionId);
                if (existingItem) {
                    // Update the existing item if it exists
                    existingItem.is_correct = 0;
                    existingItem.correct = false;

                } else {
                    // Add a new item if it doesn't exist
                    this.results.push({ question_id: questionId, is_correct: 0, correct: false });
                }


            }
        },

        getNow: function () {
            const today = new Date();
            const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + ' ' + time;
            this.timestamp = dateTime;
        },
        async fetchData() {
            const lessonId = this.$route.params.id;

            try {
                const response = await axios.get('https://backend.nadyalloughat.com/api/questions/' + lessonId, {
                    headers: {
                        'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        mode: 'no-cors',

                    },

                });
                // this.dataWordMatch = response.data.data;
                if (Array.isArray(response.data.data)) {
                    this.questions = response.data.data.map((value, index) => {
                        value.index = index + 1;
                        const arabicPattern = /[\u062a\u0641\u0627\u062d\u0629]/;
                        arabicPattern.test(value.question)
                        this.checkLang(value.question);
                        return value;

                    });
                    // this.words = response.data.data.map((value) => {
                    //     if (value.question_options && typeof value.question_options[0].option === 'string' && value.question_types.slug === 'choose-correct-translation') {
                    //         return value.question_options[0].option.split(',');
                    //     } else {
                    //         return [];
                    //     }
                    // });

                    // this.words = this.words.flat();

                    // // Initialize an object to store options grouped by question ID
                    // this.dataWordMatch = response.data.data.reduce((acc, item) => {
                    //     if (item.questionOptions && Array.isArray(item.questionOptions) && item.questionOptions.length > 0) {
                    //         const questionId = item.id;
                    //         console.log(questionId)
                    //         const options = item.questionOptions.map((option) => ({
                    //             key: option.key.toLowerCase(),
                    //             value: option.value.toLowerCase(),
                    //         }));

                    //         // Using spread operator to accumulate options for each question ID
                    //         acc[questionId] = [...options];
                    //     }
                    //     console.log(acc);
                    //     return acc;
                    // }, {});


                    // this.isMatched = Array(this.dataWordMatch[this.questions.id].length).fill(false);

                    // this.dataWordMatch = this.questions.map(item => {
                    //     return {
                    //         key: item.key,
                    //         value: item.value ? item.value.toLowerCase() : '', // Check if item.value is defined
                    //     };

                    // });

                    // console.log('this is new', this.dataWordMatch,)
                    this.totalSteps = this.questions.length;
                    console.log(this.questions.length);

                } else {
                    console.error('Response data is not an array:', response.data.data);
                }


            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.loadingDiv = false; // Set isLoading to false when data is fetched (or on error)
            }
        },
        playSound(sound) {

            if (sound) {
                if (this.currentAudio && !this.currentAudio.paused) {
                    this.currentAudio.pause();
                    this.currentAudio.currentTime = 0;
                }

                this.currentAudio = new Audio(sound)
                this.currentAudio.play();
                this.isPlaying = true;

                this.currentAudio.addEventListener('ended', () => {
                    this.isPlaying = false;
                });
            }

            // var hiddenField = document.getElementById("myHiddenField");
            // const msg = new SpeechSynthesisUtterance();
            // msg.text = hiddenField.value;
            // msg.volume = 1.0; // speech volume (default: 1.0)
            // msg.pitch = 1.0; // speech pitch (default: 1.0)
            // msg.rate = 1.0; // speech rate (default: 1.0)
            // msg.lang = 'en-GB'; // speech language (default: 'en-US')
            // msg.voiceURI = 'Google UK English Female'; // voice URI (default: platform-dependent)
            // msg.onboundary = function (event) {
            //     console.log('Speech reached a boundary:', event.name);
            // };

            // msg.onpause = function (event) {
            //     console.log('Speech paused:', event.utterance.text.substring(event.charIndex));
            // };

            // // Save the current speech synthesis instance
            // this.speechSynthesisInstance = msg;

            // // Start the new speech synthesis instance
            // window.speechSynthesis.speak(msg);
        },

        submitForm() {
            this.loadingDiv = true;
            this.notdis = false;


            const hasIncorrectAnswerss = this.results.some(answer => answer.is_correct === 0);
            if (hasIncorrectAnswerss) {
                this.hasMistak = 1

            } else {
                this.hasMistak = 0;

            }
            const lastResponsesByUniqueId = {};
            this.results.forEach(response => {
                const id = response.question_id;
                if (!lastResponsesByUniqueId[id]) {
                    lastResponsesByUniqueId[id] = response;

                } else if (response.correct) {
                    lastResponsesByUniqueId[id] = response;

                }
            });
            const lastResponses = Object.values(lastResponsesByUniqueId);

            let correctCount = lastResponses.filter(question => question.is_correct === 1).length;
            this.correctCounts = correctCount;


            const lessonId = this.$route.params.id;

            let payload = {
                // user_id: this.userId,
                category_lesson_id: lessonId,
                started_at: `${sessionStorage.getItem('startquiz') || currentDateTime()}`,
                completed_at: this.timestamp,
                questions: lastResponses,
                has_mistake: this.hasMistak,
            }

            const headers = {
                'Access-Control-Allow-Origin': '*', // Replace with the allowed origin
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json', // Specify the content type
            };
            const requestConfig = {
                method: 'post', // Use the POST method
                url: 'https://backend.nadyalloughat.com/api/progress', // Define the API endpoint URL
                headers: headers, // Set the headers
                data: payload, // Attach the payload data
            };

            // form post
            axios(requestConfig)
                .then((response) => {
                    // Handle the response here
                    if (response.data.error) {
                        // Handle the error condition
                        this.errorMessage = response.data.message; // Assuming error message is returned in the response
                    } else {
                        // The request was successful, set resultPage to true or perform other actions
                        this.resultPage = true;
                        this.finishLevel.currentTime = 0;
                        this.finishLevel.play();
                    }
                })
                .catch((error) => {
                    // Handle any errors that occur during the request
                    console.error('Error sending form data:', error);
                })
                .finally(() => {
                    // Perform actions that should occur regardless of success or failure
                });

        },
        clearwritename() {
            this.writename = '';
        },
        disablebuttonany() {
            this.notdis = false;
        },
        clearSelectedOption() {
            this.selectoptions = null;
        },
        // checkResult() {
        //     let qust = this.questions;
        //     let userAns = this.userResponses;
        //     for (let i = 0; i < qust.length; i++) {
        //         if (qust[i]?.id !== undefined && userAns[i]?.id !== undefined) {
        //             console.log("Question ID:", qust[i].id, "User Response ID:", userAns[i].id);
        //             if (qust[i].question_options[i].is_correct === userAns[i].options) {
        //                 console.log("Data Api:", qust[i].question_options.is_correct, "User Response:", userAns[i].options);
        //             }

        //         }
        //     }

        // },
        handleEnterKey(hid, hiscorrect) {
            this.checkAnswers(hid, hiscorrect);

        },
        checkAnswers(questionID) {
            if (this.currentAudio) {
                this.currentAudio.pause();
            }
            const resultIndex = this.results.findIndex(item => item.question_id === questionID);

            if (resultIndex !== -1) {
                // console.log(isCorrect);
                this.modal = true;
                if (this.results[resultIndex].is_correct === 1) {
                    this.changeColor = 'check-green';
                    this.changemColor = true;

                    this.correctSound.currentTime = 0;
                    this.correctSound.play();

                } else {
                    this.changeColor = 'check-red';
                    this.changemColor = false;
                    this.wrongSound.currentTime = 0;
                    this.wrongSound.play();
                }

                this.isAnsChecked = false;
            } else {
                // Handle the case where the questionId is not found in the results
                console.error('Question not found in results');
            }



        },
        checkedEl(el) {
            this.wizard.plateforms.push(el);
        },
        wizardFirstStep() {
            this.step = 1;
            this.resultPage = false;
            // this.stepTemp = 1;
            // this.progressbarWidth += 30;
        },
        playProgressSound() {
            this.trumpetSfx.currentTime = 0;
            this.trumpetSfx.play();
        },

        wizardNext(screenId) {
            // this.matchedValues = [];

            this.modal = false;
            this.isAllMatched = false;
            this.fillblankComponent = true;
            // this.notdis = false;
            // if (this.speechSynthesisInstance) {
            //     window.speechSynthesis.cancel();
            // }


            this.isAnsChecked = true;
            this.step++;
            // this.stepTemp++

            console.log('question lenght', this.step)
            if (this.step < this.totalSteps) {
                this.progressbarWidth = ((this.step) / this.totalSteps) * 100;
            }
            // if (this.questions.length > 0) {
            //     this.progressbarWidth = (this.results.length + 1) * 11;
            //     this.playProgressSound();
            // } else {
            //     this.progressbarWidth = 100;
            // }
            if (this.questions.length > 0) {
                this.playProgressSound();
            }


            if (this.changeColor != '') {
                this.changeColor = ''
            }

            // const indexss = this.stepIndex++;
            // console.log(i);
            // const questionToRemove = this.questions.find(question => question.id === screenId);

            const existingItem = this.results.find(item => item.question_id === screenId);
            const isccorrect = existingItem.is_correct;

            if (isccorrect === 0) {
                // this.stepTemp - 1;
                // this.progressbarWidth += 0;
                const indexToMove = this.questions.findIndex(item => item.id === screenId);

                if (indexToMove !== -1) {

                    const movedItem = this.questions.splice(indexToMove, 1)[0];

                    if (movedItem.movedCount === undefined) {
                        movedItem.movedCount = 1;
                    } else {
                        movedItem.movedCount++;
                    }

                    if (movedItem.movedCount < 2) {

                        const maxIndex = Math.max(...this.questions.map(question => question.index));
                        const newIndex = isNaN(maxIndex) ? 1 : maxIndex + 1;
                        movedItem.index = newIndex;
                        this.questions.push(movedItem);
                        // if(this.step != 1){
                        //     this.progressbarWidth = (this.results.length + 1) * 1;
                        // }

                        this.notdis = false;

                    }
                }
                this.progressbarWidth += 1;
            }

            if (isccorrect === 1) {
                // this.progressbarWidth = ((this.step + 1) / this.totalSteps) * 50;
                // this.stepTemp++
                this.notdis = false;
                // console.log(this.step, this.totalSteps)
                const indexToMove = this.questions.findIndex(item => item.id === screenId);
                if (indexToMove !== -1) {
                    this.questions.splice(indexToMove, 1);
                }
                this.progressbarWidth = ((this.step) / this.totalSteps) * 100;
            }

            this.clearSelectedOption();
            this.clearwritename();
            this.disablebuttonany();
            // console.log('Button Disabled:', this.notdis);
        },
        isLastQuestion(i) {
            // return i === this.questions.length - 1;
            if (i === this.questions.length - 1) {
                this.progressbarWidth = 100;
                return true;
            } else {
                return false;
            }

        },
        showHidefield(item) {
            if (item == 'true') {
                this.showField = true;
            }
        },
        crossGo() {
            // Access the from property of $route to get information about the previous route
            const savedPath = localStorage.getItem('currentPath');

            if (savedPath) {
                // Replace 'YourCategoryPageRoute' with the actual route name for the category page
                // this.$router.push({ name: 'lessons' });
                this.$router.push({ path: savedPath });

                localStorage.removeItem('currentPath');
                // console.log('how to do');
            } else if (!savedPath) {
                window.history.back();
                // console.log('how to not do');
            } else {
                this.$router.push({ path: savedPath });
            }
        }

    }
}
</script>